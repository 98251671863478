import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home/index.vue')
  },
  {
    path: '/Products',
    name: 'products',
    component: () => import('../views/products/index.vue'),
  },
  {
    path: '/ProductDetails',
    name: 'productDetails',
    component: () => import('../views/productDetails/index.vue')
  },

  {
    path: '/AboutUs',
    name: 'aboutUs',
    component: () => import('../views/aboutUs/index.vue')
  },
  {
    path: '/JoinUs',
    name: 'joinUs',
    component: () => import('../views/joinUs/index.vue')
  },
  {
    path: '/Cases',
    name: 'cases',
    component: () => import('../views/cases/index.vue')
  },
  {
    path: '/CasesDetails',
    name: 'casesDetails',
    component: () => import('../views/casesDetails/index.vue')
  },
  {
    path: '/News',
    name: 'news',
    component: () => import('../views/news/index.vue')
  },
  {
    path: '/NewsDetails',
    name: 'newsDetails',
    component: () => import('../views/newsDetails/index.vue')
  },
  {
    path: '/ContactUs',
    name: 'contactUs',
    component: () => import('../views/contactUs/index.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: '/',
  routes
})

export default router
